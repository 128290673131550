import AvailabilityDay from "./availabilityDay";

import { FC, useEffect, useRef, useState } from "react";
import { AVAILABILITIES } from "./availabilitiesData";

export type AvailabilitiesProps = {
  day: string;
  hours: Hour[];
}[];

export type Hour = {
  affectable: boolean;
  taken: boolean;
  selected: boolean;
  startTime: string;
  endTime: string;
};

export type AvailibilityProps = {
  setAvailabilitySelected: () => void;
};

type Props = {
  initialValues?: any;
  onSelect?: any;
  disabled?: boolean | null;
  initialAvailabilities?: AvailabilitiesProps;
  checkedAvailabilities?: any;
  isUpdate?: boolean;
  check?: boolean;
};

const Availability: FC<Props> = ({
  initialAvailabilities,
  initialValues,
  disabled,
  onSelect,
  isUpdate,
  check,
}) => {
  const [availabilities, setAvailabilities] =
    useState<AvailabilitiesProps | null>(
      initialAvailabilities || AVAILABILITIES
    );
  const prevInitialAvailabilities = useRef(initialAvailabilities);

  useEffect(() => {
    if (
      initialAvailabilities &&
      JSON.stringify(initialAvailabilities) !==
        JSON.stringify(prevInitialAvailabilities.current)
    ) {
      // Update state only if initialAvailabilities actually changed externally
      setAvailabilities(initialAvailabilities);
      prevInitialAvailabilities.current = initialAvailabilities;
    }
  }, [initialAvailabilities]);
  return (
    <div className="availability-week">
      {availabilities?.map((week) => (
        <AvailabilityDay
          check={check}
          isUpdate={isUpdate}
          onSelect={onSelect}
          key={week.day}
          availabilities={availabilities}
          setAvailabilities={setAvailabilities}
          week={week}
          initialValues={initialValues}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export default Availability;
