export const AVAILABILITIES = [
  {
    day: "Lundi",
    hours: [
      {affectable:false,
        taken: false,
        startTime: "08:00",
        endTime: "09:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "08:30",
        endTime: "09:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:00",
        endTime: "10:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:30",
        endTime: "10:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:00",
        endTime: "11:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:30",
        endTime: "11:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:00",
        endTime: "12:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:30",
        endTime: "12:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:00",
        endTime: "13:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:30",
        endTime: "13:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:00",
        endTime: "14:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:30",
        endTime: "14:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:00",
        endTime: "15:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:30",
        endTime: "15:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:00",
        endTime: "16:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:30",
        endTime: "16:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:00",
        endTime: "17:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:30",
        endTime: "17:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:00",
        endTime: "18:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:30",
        endTime: "18:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:00",
        endTime: "19:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:30",
        endTime: "19:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:00",
        endTime: "20:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:30",
        endTime: "20:30",
        selected: false,
      },
    ],
  },
  {
    day: "Mardi",
    hours: [
      {affectable:false,
        taken: false,
        startTime: "08:00",
        endTime: "09:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "08:30",
        endTime: "09:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:00",
        endTime: "10:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:30",
        endTime: "10:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:00",
        endTime: "11:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:30",
        endTime: "11:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:00",
        endTime: "12:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:30",
        endTime: "12:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:00",
        endTime: "13:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:30",
        endTime: "13:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:00",
        endTime: "14:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:30",
        endTime: "14:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:00",
        endTime: "15:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:30",
        endTime: "15:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:00",
        endTime: "16:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:30",
        endTime: "16:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:00",
        endTime: "17:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:30",
        endTime: "17:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:00",
        endTime: "18:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:30",
        endTime: "18:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:00",
        endTime: "19:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:30",
        endTime: "19:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:00",
        endTime: "20:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:30",
        endTime: "20:30",
        selected: false,
      },
    ],
  },
  {
    day: "Mercredi",
    hours: [
      {affectable:false,
        taken: false,
        startTime: "08:00",
        endTime: "09:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "08:30",
        endTime: "09:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:00",
        endTime: "10:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:30",
        endTime: "10:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:00",
        endTime: "11:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:30",
        endTime: "11:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:00",
        endTime: "12:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:30",
        endTime: "12:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:00",
        endTime: "13:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:30",
        endTime: "13:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:00",
        endTime: "14:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:30",
        endTime: "14:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:00",
        endTime: "15:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:30",
        endTime: "15:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:00",
        endTime: "16:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:30",
        endTime: "16:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:00",
        endTime: "17:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:30",
        endTime: "17:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:00",
        endTime: "18:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:30",
        endTime: "18:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:00",
        endTime: "19:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:30",
        endTime: "19:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:00",
        endTime: "20:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:30",
        endTime: "20:30",
        selected: false,
      },
    ],
  },
  {
    day: "Jeudi",
    hours: [
      {affectable:false,
        taken: false,
        startTime: "08:00",
        endTime: "09:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "08:30",
        endTime: "09:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:00",
        endTime: "10:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:30",
        endTime: "10:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:00",
        endTime: "11:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:30",
        endTime: "11:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:00",
        endTime: "12:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:30",
        endTime: "12:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:00",
        endTime: "13:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:30",
        endTime: "13:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:00",
        endTime: "14:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:30",
        endTime: "14:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:00",
        endTime: "15:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:30",
        endTime: "15:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:00",
        endTime: "16:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:30",
        endTime: "16:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:00",
        endTime: "17:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:30",
        endTime: "17:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:00",
        endTime: "18:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:30",
        endTime: "18:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:00",
        endTime: "19:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:30",
        endTime: "19:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:00",
        endTime: "20:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:30",
        endTime: "20:30",
        selected: false,
      },
    ],
  },
  {
    day: "Vendredi",
    hours: [
      {affectable:false,
        taken: false,
        startTime: "08:00",
        endTime: "09:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "08:30",
        endTime: "09:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:00",
        endTime: "10:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "09:30",
        endTime: "10:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:00",
        endTime: "11:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "10:30",
        endTime: "11:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:00",
        endTime: "12:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "11:30",
        endTime: "12:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:00",
        endTime: "13:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "12:30",
        endTime: "13:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:00",
        endTime: "14:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "13:30",
        endTime: "14:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:00",
        endTime: "15:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "14:30",
        endTime: "15:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:00",
        endTime: "16:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "15:30",
        endTime: "16:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:00",
        endTime: "17:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "16:30",
        endTime: "17:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:00",
        endTime: "18:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "17:30",
        endTime: "18:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:00",
        endTime: "19:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "18:30",
        endTime: "19:30",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:00",
        endTime: "20:00",
        selected: false,
      },
      {affectable:false,
        taken: false,
        startTime: "19:30",
        endTime: "20:30",
        selected: false,
      },
    ],
  },
];
