import moment from "moment";
import { SessionFilterOptionsType } from "../ts/types";
import { GetCurrentOrigin } from "./Location";

export const WEB_APP_URL = GetCurrentOrigin();
export enum Gender {
  MALE = "male",
  FEMALE = "female",
}
export enum UserCreatedBy {
  ADMIN = "admin",
  CLASSIC = "classic",
}
export enum UserRoles {
  TUTOR = "tutor",
  PARENT = "parent",
  STUDENT = "student",
  ADMIN = "super_administrator",
  MANAGER = "manager",
  COORDINATOR = "coordinator",
  VSC = "vsc",
}

export enum PermissionActions {
  CREATE = "create",
  READ = "read",
  UPDATE = "update",
  DELETE = "delete",
}

export enum RedirectPaths {
  HOME = "/",
  TUTOR_LOGIN = "/tutor/login",
  PARENT_LOGIN = "/parent/login",
  STUDENT_LOGIN = "/student/login",
  IS_LOGIN = "/IS/login",
  UPDATE_YOUR_PASSWORD = "/update-your-password",
}

export enum RedirectRegisterPaths {
  HOME = "/",
  TUTOR_REGISTER = "/tutor/register",
  PARENT_REGISTER = "/parent/register",
  STUDENT_REGISTER = "/student/register",
}

export enum StatusCodes {
  EMAIL = 2039,
  NO_CONTENT = 2004,
  USER_IS_NOT_ACTIVE = 2033,
  USER_MUST_FINISH_REGISTRATION = 2041,
}
export const typeOfEstablishments = {
  school: [
    { id: 1, name: "elementary-school" },
    { id: 2, name: "middle-school" },
    { id: 3, name: "high-school" },
  ],
  higherSchool: [
    { id: 4, name: "business-school" },
    { id: 5, name: "engineering-school" },
    { id: 6, name: "iut" },
    { id: 7, name: "university" },
    { id: 8, name: "ufr" },
    { id: 12, name: "École" },
    { id: 13, name: "Grand établissement" },
    { id: 14, name: "ecole superieure" },
  ],
  structure: [
    { id: 9, name: "company" },
    { id: 10, name: "association" },
    { id: 11, name: "other" },
    { id: 15, name: "autre établissement" },
  ],
};

export const UserProgramList = {
  HOME_CLASSES: { name: "HomeClasse", programId: "az-rem-4ca0f8d" },
  DEVOIRS_FAITS: { name: "Tutorat solidaire", programId: "az-fac-5f87232" },
  ZUPDEFOOT: { name: "ZUPdeFOOT", programId: "az-zup-6g98343" },
  ClassHome: { name: "ClassHome", programId: "az-per-2ca0z9s" },
};

export enum StatusOfEstablishment {
  ACTIVE = "Active",
  PROSPECT = "Prospect",
  INACTIVE = "Inactive",
}

export enum AppreciationOfThePartnership {
  BAD = "Bad",
  AVERAGE = "Average",
  GOOD = "Good",
  VERYGOOD = "Very good",
}

export enum UserStatus {
  ACTIVE = "active",
  DEACTIVETED = "deactiveted",
  COMMITMENT_END = "commitment-end",
  PREEMPTIVE_END = "preemptive-end",
  DELETED = "deleted",
  SUSPENDED = "suspended",
  PENDING = "pending",
  ON_HOLD = "on-hold",
}

export enum StudentStatus {
  NEW = "new",
  AWAITING_TUTOR = "awaiting-tutor",
  IN_TUTORING = "in-tutoring",
  STOP = "stop",
}

export enum sessionType {
  SESSION_0 = "0",
  DEVOIRS_FAITS = "devoirs-faits",
  SESSION_CALENDAR = "sessions-calendar",
}

export enum sessionRecurrence {
  ONE_TIME = "one-time",
  WEEKLY = "weekly",
  BI_MONTHLY = "bi-monthly",
}

export const reportStatus = {
  validated: { en: "validated", fr: "validés", status: "success" },
  entered: { en: "entered", fr: "saisis", status: "warning" },
  to_entered: { en: "toEnter", fr: "a saisis", status: "default" },
};

export const ORDINAL_CHILD_NUMBERS = [
  "Second",
  "Third",
  "Fourth",
  "Fifth",
  "Sixth",
  "Seventh",
  "Eighth",
  "Ninth",
  "Tenth",
];

export const MAX_CHILDREN_TO_REGISTER = ORDINAL_CHILD_NUMBERS.length - 1;
export const DATE_FORMAT = "DD/MM/YYYY";
export const SESSION_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const DATE_TIME_FORMAT = "YYYY-MM-DDHH:mm:ss";
export const HOURS_AND_MINUTES_FORMAT = "HH:mm";
export const ZUPDECO_WEBSITE = "https://zupdeco.org";
export const SESSION_DASHBOARD_LIST_FORMAT = "MMM DD, YYYY";
export const FULL_FORMAT_WITH_TZ = "YYYY-MM-DDTHH:mm:ssZ";

export const DAY_OF_THE_WEEK = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
];

export const DAY_OF_THE_WEEK_AND_WEEKENDS = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

export const MONTH = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
];

export const MONTH_SHORT = [
  "Janv",
  "Févr",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juil",
  "Août",
  "Sept",
  "Oct",
  "Nov",
  "Déc",
];

export const calendarsColor = [
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "danger",
];

export const OneToFiveGrades = [1, 2, 3, 4, 5];
export const DisabledHours = [0, 1, 2, 3, 4, 5];
export const DisabledMinutes = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
  43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
];

export const GOOGLE_MAPS_PLACES_API = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places&callback=Function.prototype`;

export const ZUPDECO_DOMAIN = {
  protocol: "https://",
  domain: "zupdeco.fr",
};

export const DEFAULT_COUNTRY_CODE = "fr";

export const ISRootPath = "/";

export enum ISManagePaths {
  TUTORS = "/manage/tutors",
  PARENTS = "/manage/parents",
  STUDENTS = "/manage/students",
  MATCHING = "/manage/matching",
  ESTABLISHMENTS = "/manage/establishments",
  VSC = "/manage/vsc",
}

export enum ISTutoringPaths {
  SESSIONS = "/tutoring/sessions",
  CALENDAR = "/tutoring/calendar",
  CR_REPORTS = "/tutoring/cr-reports",
  SC_REPORTS = "/tutoring/sc-reports",
}

export enum ISSetupPaths {
  SCHOLAR_YEAR = "/setup/scholar-year",
  SCHOOL_ZONES = "/setup/school-zones",
  PUBLIC_HOLIDAYS = "/setup/public-holidays",
  AVAILABILITY = "/setup/availability",
  SECTORS = "/setup/sectors",
}

export enum ISProfileRolesPaths {
  ADMINISTRATORS = "/profile/administrators",
  MANAGERS = "/profile/managers",
  COORDINATORS = "/profile/coordinators",
}

export enum AuthPaths {
  HOME = "/choose",
  TUTOR_LOGIN = "/tutor/login",
  TUTOR_REGISTER = "/tutor/register",
  TUTOR_CHANGE_PASSWORD = "/change-password",
  TUTOR_RECOVERY_PASSWORD = "/tutor/recover-password",
  TUTOR_RESET_PASSWORD = "/tutor/reset-password",
  RESET_PASSWORD = "/reset-password",

  PARENT_LOGIN = "/parent/login",
  PARENT_REGISTER = "/parent/register",
  PARENT_RECOVERY_PASSWORD = "/parent/recover-password",
  PARENT_RESET_PASSWORD = "/parent/reset-password",
  STUDENT_LOGIN = "/student/login",
  STUDENT_REGISTER = "/student/register",
  STUDENT_RECOVERY_PASSWORD = "/student/recover-password",
  STUDENT_RESET_PASSWORD = "/student/reset-password",
  IS_USER_LOGIN = "/IS/login",
  IS_USER_REGISTER = "/IS/register",
  IS_USER_RECOVERY_PASSWORD = "/IS/recover-password",
  IS_USER_RESET_PASSWORD = "/IS/reset-password",
  UPDATE_YOUR_PASSWORD = "/update-your-password",
}

export const TABLE_PAGE_SIZE = 10;

export const ParentFilterOptions = {
  email: "",
  firstName: "",
  lastName: "",
  status: null,
  city: null,
  study: null,
};

export const StudentFilterOptions = {
  email: "",
  firstName: "",
  lastName: "",
  userStatus: null,
  program: null,
  department: null,
  establishment: null,
  partner: null,
  folderStatus: null,
  "parent-email": "",
};
export const EstablishmentFilterOptions = {
  typeOfEstablishment: "",
  sectors: null,
  coordinators: null,
  establishmentName: "",
  city: "",
};

export const TutorFilterOptions = {
  email: "",
  firstName: "",
  lastName: "",
  status: null,
  program: null,
  department: null,
  establishment: undefined,
  study: null,
};
//current week

export const SessionFilterOptions: SessionFilterOptionsType = {
  sector: null,
  sessionType: null,
  sessionStatus: "session-0-to-be-scheduled",
  program: null,
  tutorUserId: null,
  studentUserId: null,
  vsc: null,
  //current week
  byDate: [moment().startOf("week"), moment().endOf("week")],
  recurence: null,
  establishment: null,
};

export const MatchingsFilterOptions = {
  program: "az-rem-4ca0f8d",
  establishment: null,
  status: null,
  student: "",
  mode: "tutor",
};
const lastMonth = moment().subtract(1, "month");
const currentdate = moment();
export const CrReportsFilterOptions = {
  userType: null,
  sessionId: null,
  studentsAttendance: null,
  tutorsAttendance: null,
  status: null,
  byDate: [moment().startOf("week"), moment().endOf("week")],
  grade: null,
  school: null,
  tutorFullName: null,
  studentFullName: null,
  vscFullName: null,
  program: null,
};

export const ScReportsFilterOptions = {
  byDate: null,
  coordinatorName: null,
  vscName: null,
  status: null,
};

export const MAXICOURS_URL = "https://www.maxicours.com/";
export const EMPTY_VALUE = "";
export const EMPTY_VALUE_SHORT = "-";
export const INVALID_TRANSLATION = "invalid-translation";

export enum ACCESS_RULES {
  READ = "R",
  READ_AND_WRITE = "RW",
  NO_ACCESS = "N",
}

export enum ON_BOARDING_STATUS {
  IN_PROGRESS = "on-boarding-in-progress",
  IN_REVIEW = "on-boarding-in-review",
  COMPLETED = "on-boarding-completed",
}

export const SMALL_MODAL = 1500;

export const ReportsStatus = {
  toBeEntered: "to-be-entered",
  entered: "entered",
  confirmed: "confirmed",
};

export const AbsenceStatus = {
  notReported: null,
  present: true,
  notPresent: false,
};

export const ReportStatusColors: any = {
  toBeEntered: "default",
  entered: "warning",
  confirmed: "success",
};

export const AbsenceStatusColors: any = {
  notReported: "default",
  notPresent: "error",
  present: "success",
};

export const ReportsTypes = {
  crReport: "cr-report-az-1997",
  scReport: "sc-report-az-1998",
};

export const colorsClass = [
  "avatar-primary-1",
  "avatar-secondary-1",
  "avatar-info-1",
  "avatar-success-1",
  "avatar-warning-1",
];

export const TuTorMinimumAge = 16;

export const activityDomain = [
  "Enseignement et formation",
  "recherche",
  "autres",
  "conseil et ingenieurie",
  "santé",
  "informatique et télécom",
  "automobile",
  "commerce",
  "transports et logistique",
  "BTP et immobilier",
  "banques et assurances",
  "artisanat",
  "textile",
  "énergie",
  "édition",
  "communication et marketing",
  "press et media",
  "droit et justice",
  "architecture et urbanisme",
  "Environnement",
  "social",
  "administration publique",
  "art et design",
  "aéronautique et spatiale",
  "agroalimentaire",
  "pharmaceutique",
];

export const ILE_DE_FRANCE_DEPARTMENTS = [
  {
    value: "Ain",
    key: "01",
  },
  {
    value: "Aisne",
    key: "02",
  },
  {
    value: "Allier",
    key: "03",
  },
  {
    value: "Alpes-de-Haute-Provence",
    key: "04",
  },
  {
    value: "Hautes-Alpes",
    key: "05",
  },
  {
    value: "Alpes-Maritimes",
    key: "06",
  },
  {
    value: "Ardèche",
    key: "07",
  },
  {
    value: "Ardennes",
    key: "08",
  },
  {
    value: "Ariège",
    key: "09",
  },
  {
    value: "Aube",
    key: "10",
  },
  {
    value: "Aude",
    key: "11",
  },
  {
    value: "Aveyron",
    key: "12",
  },
  {
    value: "Bouches-du-Rhône",
    key: "13",
  },
  {
    value: "Calvados",
    key: "14",
  },
  {
    value: "Cantal",
    key: "15",
  },
  {
    value: "Charente",
    key: "16",
  },
  {
    value: "Charente-Maritime",
    key: "17",
  },
  {
    value: "Cher",
    key: "18",
  },
  {
    value: "Corrèze",
    key: "19",
  },
  {
    value: "Côte-d'Or",
    key: "21",
  },
  {
    value: "Côtes-d'Armor",
    key: "22",
  },
  {
    value: "Creuse",
    key: "23",
  },
  {
    value: "Dordogne",
    key: "24",
  },
  {
    value: "Doubs",
    key: "25",
  },
  {
    value: "Drôme",
    key: "26",
  },
  {
    value: "Eure",
    key: "27",
  },
  {
    value: "Eure-et-Loir",
    key: "28",
  },
  {
    value: "Finistère",
    key: "29",
  },
  {
    value: "Corse-du-Sud",
    key: "2A",
  },
  {
    value: "Haute-Corse",
    key: "2B",
  },
  {
    value: "Gard",
    key: "30",
  },
  {
    value: "Haute-Garonne",
    key: "31",
  },
  {
    value: "Gers",
    key: "32",
  },
  {
    value: "Gironde",
    key: "33",
  },
  {
    value: "Hérault",
    key: "34",
  },
  {
    value: "Ille-et-Vilaine",
    key: "35",
  },
  {
    value: "Indre",
    key: "36",
  },
  {
    value: "Indre-et-Loire",
    key: "37",
  },
  {
    value: "Isère",
    key: "38",
  },
  {
    value: "Jura",
    key: "39",
  },
  {
    value: "Landes",
    key: "40",
  },
  {
    value: "Loir-et-Cher",
    key: "41",
  },
  {
    value: "Loire",
    key: "42",
  },
  {
    value: "Haute-Loire",
    key: "43",
  },
  {
    value: "Loire-Atlantique",
    key: "44",
  },
  {
    value: "Loiret",
    key: "45",
  },
  {
    value: "Lot",
    key: "46",
  },
  {
    value: "Lot-et-Garonne",
    key: "47",
  },
  {
    value: "Lozère",
    key: "48",
  },
  {
    value: "Maine-et-Loire",
    key: "49",
  },
  {
    value: "Manche",
    key: "50",
  },
  {
    value: "Marne",
    key: "51",
  },
  {
    value: "Haute-Marne",
    key: "52",
  },
  {
    value: "Mayenne",
    key: "53",
  },
  {
    value: "Meurthe-et-Moselle",
    key: "54",
  },
  {
    value: "Meuse",
    key: "55",
  },
  {
    value: "Morbihan",
    key: "56",
  },
  {
    value: "Moselle",
    key: "57",
  },
  {
    value: "Nièvre",
    key: "58",
  },
  {
    value: "Nord",
    key: "59",
  },
  {
    value: "Oise",
    key: "60",
  },
  {
    value: "Orne",
    key: "61",
  },
  {
    value: "Pas-de-Calais",
    key: "62",
  },
  {
    value: "Puy-de-Dôme",
    key: "63",
  },
  {
    value: "Pyrénées-Atlantiques",
    key: "64",
  },
  {
    value: "Hautes-Pyrénées",
    key: "65",
  },
  {
    value: "Pyrénées-Orientales",
    key: "66",
  },
  {
    value: "Bas-Rhin",
    key: "67",
  },
  {
    value: "Haut-Rhin",
    key: "68",
  },
  {
    value: "Rhône",
    key: "69",
  },
  {
    value: "Haute-Saône",
    key: "70",
  },
  {
    value: "Saône-et-Loire",
    key: "71",
  },
  {
    value: "Sarthe",
    key: "72",
  },
  {
    value: "Savoie",
    key: "73",
  },
  {
    value: "Haute-Savoie",
    key: "74",
  },
  {
    value: "Paris",
    key: "75",
  },
  {
    value: "Seine-Maritime",
    key: "76",
  },
  {
    value: "Seine-et-Marne",
    key: "77",
  },
  {
    value: "Yvelines",
    key: "78",
  },
  {
    value: "Deux-Sèvres",
    key: "79",
  },
  {
    value: "Somme",
    key: "80",
  },
  {
    value: "Tarn",
    key: "81",
  },
  {
    value: "Tarn-et-Garonne",
    key: "82",
  },
  {
    value: "Var",
    key: "83",
  },
  {
    value: "Vaucluse",
    key: "84",
  },
  {
    value: "Vendée",
    key: "85",
  },
  {
    value: "Vienne",
    key: "86",
  },
  {
    value: "Haute-Vienne",
    key: "87",
  },
  {
    value: "Vosges",
    key: "88",
  },
  {
    value: "Yonne",
    key: "89",
  },
  {
    value: "Territoire de Belfort",
    key: "90",
  },
  {
    value: "Essonne",
    key: "91",
  },
  {
    value: "Hauts-de-Seine",
    key: "92",
  },
  {
    value: "Seine-Saint-Denis",
    key: "93",
  },
  {
    value: "Val-de-Marne",
    key: "94",
  },
  {
    value: "Val-d'Oise",
    key: "95",
  },
  {
    value: "Guadeloupe",
    key: "971",
  },
  {
    value: "Martinique",
    key: "972",
  },
  {
    value: "Guyane",
    key: "973",
  },
  {
    value: "La Réunion",
    key: "974",
  },
];

export const introductionVideos = [
  {
    title: "ZUPdeCO - Dans quoi je m'engage ?",
    url: "https://www.youtube.com/embed/Dy07XMAZidc?si=aH_wJu8apjH08EoA",
  },
  {
    title: "ZUPdeCO - Mes outils",
    url: "https://www.youtube.com/embed/Pr8_SIQv8mQ?si=k6gwjEOphx2-PMeP",
  },
  {
    title: "ZUPdeCO - Mon référent ZUPdeCO",
    url: "https://www.youtube.com/embed/h84wE2tA7DE?si=aYcFQ6Gc7cs9G6dm",
  },
  {
    title: "ZUPdeCO - Quelques rappels importants",
    url: "https://www.youtube.com/embed/OEn4Dg-UJZo?si=O7AkHyHHU_gJV00a",
  },
  {
    title: "ZUPdeCO - Questions fréquentes",
    url: "https://www.youtube.com/embed/C9A6fwtrLdw?si=nFZ5CVE9lTHEnwGk",
  },
  {
    title: "ZUPdeCO - La première rencontre avec mon élève",
    url: "https://www.youtube.com/embed/oExO-COTU4U?si=FFrb0SLUcVOjzehW",
  },
  {
    title: "ZUPdeCO - Une séance type",
    url: "https://www.youtube.com/embed/TBSFL7DuvmY?si=80IThhDFzTbhCBEy",
  },
  {
    title: "ZUPdeCO - Des questions ?",
    url: "https://www.youtube.com/embed/lTVvteTeCOI?si=oF7qi4UbDgQlSbEY",
  },
];

export const parentIntroductionVideos = [
  {
    title: "ZUPdeCO - Dans quoi je m'engage ?",
    url: "https://www.youtube.com/embed/wD2NDJOwcwI?si=wcUVNBT2KyprVoTh",
  },
  {
    title: "ZUPdeCO - Mes outils",
    url: "https://www.youtube.com/embed/lTLbpRR06Dg?si=8lX9dyxzzz1su9JW",
  },
  {
    title: "ZUPdeCO - Mon référent ZUPdeCO",
    url: "https://www.youtube.com/embed/JUdi9qkAN0k?si=bKpMkXcc2DMTdDDd",
  },
  {
    title: "ZUPdeCO - Quelques rappels importants",
    url: "https://www.youtube.com/embed/Cs2d7r0nlTk?si=wvivkyu0f1aaKcEU",
  },
  {
    title: "ZUPdeCO - Questions fréquentes",
    url: "https://www.youtube.com/embed/rHzT91YIE3w?si=K3glGhsgntfRwaSY",
  },
  {
    title: "ZUPdeCO - La première rencontre avec mon mentor",
    url: "https://www.youtube.com/embed/-Ro0UrwXv2I?si=N3I3xw_IWDNwZNPr",
  },
  {
    title: "ZUPdeCO - Une séance type",
    url: "https://www.youtube.com/embed/fJ3L1Q2VMvc?si=RPErGFQv2t7frr7e",
  },
  {
    title: "ZUPdeCO - Des questions ?",
    url: "https://www.youtube.com/embed/4j8vjvZ9pto?si=cK1321xLnMORgCuz",
  },
];
export const affectability = [
  { value: "unmatched", name: "Non affectable" },
  { value: "private-session", name: "Affectable" },
];
export const Affectations = [
  { value: "unmatched", name: "Non affecté" },
  { value: "partial", name: "Partiel" },
  { value: "private-session", name: "Affecté" },
];
export const Genders = [
  { value: "male", name: "Homme" },
  { value: "female", name: "Femme" },
];
export const Levels = [
  {
    text: "Primaire",
    value: "Primaire",
    children: [
      {
        text: "CM1",
        value: "CM1",
      },
      {
        text: "CM2",
        value: "CM2",
      },
    ],
  },
  {
    text: "Collège",
    value: "Collège",
    children: [
      {
        text: "6eme",
        value: "6eme",
      },
      {
        text: "5eme",
        value: "5eme",
      },
      {
        text: "4eme",
        value: "4eme",
      },
      {
        text: "3eme",
        value: "3eme",
      },
    ],
  },
  {
    text: "Lycée",
    value: "Lycée",
    children: [
      {
        text: "Seconde",
        value: "Seconde",
      },
      {
        text: "Première",
        value: "Première",
      },
      {
        text: "Terminale",
        value: "Terminale",
      },
    ],
  },
];
export const levelsFromDb = [
  {
    _id: "640bacd8ccd55123460565d8",
    levelName: "CM1",
    levelDescription: "",
    group: "Primaire",
  },
  {
    _id: "640bacdeccd55123460565db",

    levelName: "CM2",
    levelDescription: "",
    group: "Primaire",
  },
  {
    _id: "6410db375ce45579d4e538e2",

    levelName: "6eme",
    levelDescription: "",
    group: "Collège",
  },
  {
    _id: "64395b939f1193aca486bab2",

    levelName: "5eme",
    levelDescription: ".",
    group: "Collège",
  },
  {
    _id: "64395ba29f1193aca486bab5",

    levelName: "4eme",
    levelDescription: ".",
    group: "Collège",
  },
  {
    _id: "64395bac9f1193aca486bab8",

    levelName: "3eme",
    levelDescription: ".",
    group: "Collège",
  },
  {
    _id: "64395bb49f1193aca486babb",

    levelName: "Seconde",
    levelDescription: ".",
    group: "Lycée",
  },
  {
    _id: "64395bbd9f1193aca486babe",

    levelName: "Première",
    levelDescription: ".",
    group: "Lycée",
  },
  {
    _id: "64395bc89f1193aca486bac1",

    levelName: "Terminale",
    levelDescription: ".",
    group: "Lycée",
  },
];
export const SupportedStudents = [
  // {
  //   value: 0,
  //   text: "0",
  // },
  {
    value: 1,
    text: "1",
  },
  {
    value: 2,
    text: "2",
  },
  {
    value: 3,
    text: "3",
  },
  {
    value: 4,
    text: "4",
  },
  {
    value: 5,
    text: "5",
  },
  {
    value: 6,
    text: "6",
  },
  {
    value: 7,
    text: "7",
  },
  {
    value: 8,
    text: "8",
  },
  {
    value: 9,
    text: "9",
  },
  {
    value: 10,
    text: "10",
  },
  {
    value: 11,
    text: "11",
  },
  {
    value: 12,
    text: "12",
  },
  {
    value: 13,
    text: "13",
  },
  {
    value: 14,
    text: "14",
  },
  {
    value: 15,
    text: "15",
  },
  {
    value: 16,
    text: "16",
  },
  {
    value: 17,
    text: "17",
  },
  {
    value: 18,
    text: "18",
  },
  {
    value: 19,
    text: "19",
  },
  {
    value: 20,
    text: "20",
  },
];

export const FootballClubs = [
  "Blois Foot",
  "FC Lyon",
  "Sco Angers",
  "US Orléans",
  "Bourges foot 18",
  "Racing CF",
  "LOSC",
  "FC Fleury",
  "Vigneux S/Seine",
  "Paris FC",
  "Berrichonne",
  "Red Star",
  "Estac Troyes",
  "Olympique de Marseille",
];

// establishment: [
//   "Ecole maternelle Victor Hugo (Saint-Cyr-l'Ecole)",
//   "Ecole primaire Hugo (Yutz)",
//   {
//     establishmentId: "66b0df7bf375b6bf39dae4f6",
//     establishmentName: "Ecole maternelle (Ambronay)",
//   },
//   {
//     establishmentId: "66b0df7bf375b6bf39da658a",
//     establishmentName:
//       "Lycée professionnel Alexandre Bérard (Ambérieu-en-Bugey)",
//   },
// ];
