import Cookies from "universal-cookie";

import { TABLE_PAGE_SIZE } from "../utils/const";
import { Console } from "../utils";
import { axiosInstance } from "../hooks/useAxiosInterceptor";

const cookies = new Cookies();

// Fetch students based on id
export const fetchStudentsById = async (studentId: string) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/student/detail?studentId=${studentId}`,
    headers: {
      // userId: cookies.get("userId"),
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
    },
  });
};

// Fetch students based on name and surname
export const fetchStudentsAutoComplete = async ({
  queryKey: [
    _,
    search,
    programId,
    selectedStudents,
    establishments,
    department,
  ],
}: any) => {
  const params = new URLSearchParams();

  if (search) params.append("search", search);
  if (programId) params.append("program", programId);
  if (selectedStudents)
    params.append("selectedStudents", JSON.stringify(selectedStudents));

  if (establishments && establishments.length > 0) {
    params.append("establishments", JSON.stringify(establishments));
  }
  if (department && department.length > 0) {
    params.append("department", JSON.stringify(department));
  }

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/student/search?${params.toString()}`,
    headers: {
      // userId: cookies.get("userId"),
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
    },
  });
};

// Fetch tutors based on id
export const fetchTutorsById = async (tutorId: string) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/detail?tutorId=${tutorId}`,
  });
};

// Fetch students based on name and surname
export const fetchTutorsAutoComplete = async ({
  queryKey: [_, search, programId],
}: any) => {
  return await axiosInstance({
    method: "get",
    headers: {
      userId: cookies.get("userId"),
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
    },
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/search?search=${search}&program=${programId}`,
  });
};
// Fetch all establishmend based on filter, sort, and page queries
export const fetchAllEducationAnnuaireEstablishments = async ({
  queryKey: [_, filter, sortBy, page],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/establishment/dashboard/annuaire?filter=${JSON.stringify(
      filter
    )}&sortBy=${JSON.stringify(
      sortBy
    )}&pageSize=${TABLE_PAGE_SIZE}&page=${JSON.stringify(page)}`,
  });
};
// Fetch all establishmend based on filter, sort, and page queries
export const fetchEstablishments = async ({
  queryKey: [_, filter, sortBy, page],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/establishment/dashboard?filter=${JSON.stringify(
      filter
    )}&sortBy=${JSON.stringify(
      sortBy
    )}&pageSize=${TABLE_PAGE_SIZE}&page=${JSON.stringify(page)}`,
  });
};

// Establoshments: fetch all no radar
export const noradarEstablishments = async ({ queryKey: [_] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment/dashboard?&pageSize="99999999"&page="1"`,
  });
};
//Fecth all Education-annuaire establishment
export const fetchAllAnnuaireEstablishment = async (
  city: string = "",
  queryKey: string = "",
  page: number = 0,
  queryId: number | null = null
) => {
  // const stringyfiedType = JSON.stringify(type);

  try {
    const url = `${process.env.REACT_APP_SERVER_URL}/establishment/dashboard/phonebook?search=${queryKey}&city=${city}&page=${page}&queryId=${queryId}`;

    const response = await axiosInstance({
      method: "get",
      url: url,
    });

    console.log("API response data:", response.data);
    return response;
  } catch (error) {
    console.error("API request failed:", error);
    return error;
  }
};

//Fecth all tutor establishments
export const fetchAllTutorEstablishments = async (
  queryKey: string,
  type: string[]
) => {
  const stringyfiedType = JSON.stringify(type);
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment/tutorEstablishment?search=${queryKey}&type=${stringyfiedType}`,
  });
};

// Fetch all establishments
export const fetchAllEstablishments = async ({ queryKey: [_] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment/dashboard`,
  });
};

// Fetch all establishmmend For CSV download based on filter, sort, and page queries
export const fetchEstablishmentsCSV = async ({
  queryKey: [_, filter, sortBy, page],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/establishment/dashboard/csv?filter=${JSON.stringify(
      filter
    )}&sortBy=${JSON.stringify(
      sortBy
    )}&pageSize=${TABLE_PAGE_SIZE}&page=${JSON.stringify(page)}`,
  });
};

// Fetch autocomplete results for establishment
export const fetchEstablishmentsAutoComplete = async ({
  queryKey: [_, filterQuery],
}: any) => {
  const FormData = require("form-data");
  const data = new FormData();

  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/establishment/autoComplete?filter=${JSON.stringify(filterQuery)}`,

    data,
  });
};
// ESTABLISHMENTS - Base on typeOfEstablishments
export const fetchEstablishmentsBasedOnIds = async ({
  queryKey: [_, establishmentIds],
}: any) => {
  const FormData = require("form-data");
  const data = new FormData();

  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/establishment/baseOnTypeOfEstablishment?typeOfEstablishmentIds=${JSON.stringify(
      establishmentIds
    )}`,

    data,
  });
};

// Delete Establishment by Establishment ID
export const deleteEstablishment = async (establishmentId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment?establishmentId=${establishmentId}`,
  });
};

// Get Establishment by Establishment ID
export const getEstablishment = async ({
  queryKey: [_, establishmentId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment?establishmentId=${establishmentId}`,
  });
};

// Get Establishments based on sector ID
export const getEstablishmentsOfSector = async ({
  queryKey: [_, sectorIDs],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/establishment/baseOnSectors?sectorIds=${JSON.stringify(sectorIDs)}`,
  });
};

// Get Sector based on establishment ID
export const fetchSectorsBasedOnEstablishments = async ({
  queryKey: [_, establishmentIDs],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/sector/baseOnEstablishments?establishmentIds=${JSON.stringify(
      establishmentIDs
    )}`,
  });
};
export const fetchSectorsBasedOnDepartments = async (
  departmentId: string,
  programId: string
) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/sector/basedOnDepartments`,
    params: {
      departmentId,
      programId,
    },
  });
};
// Add Establishment
export const addEstablishment = async ({ establishmentData }: any) => {
  const data = new FormData();
  data.append("establishment", JSON.stringify(establishmentData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment`,

    data,
  });
};

// Add Tutor Establishment
export const addTutorEstablishment = async ({ establishmentData }: any) => {
  const data = new FormData();
  data.append("establishment", JSON.stringify(establishmentData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment/tutor`,

    data,
  });
};

// Update Establishment
export const updateEstablishment = async ({ establishmentData }: any) => {
  const data = new FormData();
  data.append("establishment", JSON.stringify(establishmentData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment`,

    data,
  });
};

//Check if establishment name exist
export const checkEstablishmentName = async ({
  queryKey: [_, establishmentName],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/establishment/checkEstablishmentName?establishmentName=${establishmentName}`,
  });
};

// LEVELS: GET all levels
export const fetchLevels = async () => {
  const FormData = require("form-data");
  const data = new FormData();

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/levels`,
  });
};

// LEVELS: GET level by levelId
export const getLevel = async ({ queryKey: [_, levelId] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/level?levelId=${levelId}`,
  });
};

// LEVELS: add Level
export const addLevel = async ({ levelData }: any) => {
  const data = new FormData();
  data.append("level", JSON.stringify(levelData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/level`,

    data,
  });
};

// LEVELS: Update Level
export const updateLevel = async ({ levelData }: any) => {
  const data = new FormData();
  data.append("level", JSON.stringify(levelData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/level`,

    data,
  });
};

// SESSIONS - Dashboard
export const fetchSessions = async ({ queryKey: [_, filters] }: any) => {
  const { page, pageSize, filter, exportCsv, sessionStatus } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/sessions/dashboard${queryString}`,
  });
};

// SESSIONS - Dashboard CSV
export const fetchSessionsCsv = async (filters: any) => {
  const { page, pageSize, filter, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/sessions/dashboard${queryString}`,
  });
};

// REPORTS: Fetch all Reports with filters
export const fetchReports = async ({ queryKey: [_, filters] }: any) => {
  const { reportType, page, pageSize, filter, sortBy, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?typeOfReport=${reportType}&page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}&userId=${cookies.get(
    "userId"
  )}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;
  if (sortBy !== null) queryString += `&sortBy=${JSON.stringify(sortBy)}`;

  Console("queryString", queryString);

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/crscreport/dashboard${queryString}`,
  });
};

// Reports - CR Reports with Filters, Sorting and Exprot CSV Data
export const fetchReportsCsv = async (filters: any) => {
  const { reportType, page, pageSize, filter, sortBy, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?typeOfReport=${reportType}&page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}&userId=${cookies.get(
    "userId"
  )}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;
  if (sortBy !== null) queryString += `&sortBy=${JSON.stringify(sortBy)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/crscreport/dashboard${queryString}`,
  });
};

// REPORTS: Fetch all Sc Reports with filters
export const fetchScReports = async ({ queryKey: [_, filters] }: any) => {
  const { page, pageSize, filter, sortBy, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;
  if (sortBy !== null) queryString += `&sortBy=${JSON.stringify(sortBy)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/scReport/dashboard${queryString}`,
  });
};

export const fetchScReportsCsv = async (filters: any) => {
  const { page, pageSize, filter, sortBy, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;
  if (sortBy !== null) queryString += `&sortBy=${JSON.stringify(sortBy)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/scReport/dashboard${queryString}`,
  });
};

// REPORTS: create new report
export const addReport = async ({ reportData }: any) => {
  const data = new FormData();
  data.append("reportData", JSON.stringify(reportData));

  return await axiosInstance({
    data: data,
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/crscreport?userId=${cookies.get(
      "userId"
    )}`,
  });
};

// REPORTS: Fetch CRS Report with Id
export const fetchReport = async ({ queryKey }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/crscreport?sessionId=${queryKey[1]}`,
  });
};

// REPORTS: Fetch SC Report with Id
export const fetchScReport = async ({ queryKey }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/scReport?scReportId=${queryKey[1]}`,
  });
};

// REPORTS: Update Cr by ID
export const updateCr = async ({ crData }: any) => {
  const data = new FormData();
  data.append("studentData", JSON.stringify(crData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/cr/is`,

    data,
  });
};

// REPORTS: Update Report by ID
export const updateReport = async ({ reportData }: any) => {
  const data = new FormData();
  data.append("reportData", JSON.stringify(reportData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/crscreport`,

    data,
  });
};

// REPORTS: Update Sc Report by ID
export const updateScReport = async ({ scReport }: any) => {
  const data = new FormData();
  data.append("scReport", JSON.stringify(scReport));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/scReport`,

    data,
  });
};

// REPORTS: Fetch CRS Report with Id
export const deleteReport = async (reportId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/crscreport?reportId=${reportId}`,
  });
};

// REPORTS: Fetch SC Report with Id
export const deleteScReport = async (reportId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/scReport?scReportId=${reportId}`,
  });
};

// SESSIONS - session details
export const getSession = async ({ queryKey: [_, sessionId] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/session?sessionId=${sessionId}`,
  });
};

// Add Session
export const addSession = async ({ sessionData, origin, meet }: any) => {
  const data = new FormData();
  data.append("sessionData", JSON.stringify(sessionData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/sessions/sessions-zero?origin=${origin}&meet=${meet}`,
    data,
  });
};

// Update Session
export const updateSession = async ({ sessionData }: any) => {
  const data = new FormData();
  data.append("sessionData", JSON.stringify(sessionData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/session`,

    data,
  });
};

// Sessions join-url
export const sessionJoinUrl = async ({
  queryKey: [_, userId, sessionId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/session/join-url?userId=${userId}&sessionId=${sessionId}`,
  });
};

// Sessions: Delete
// i have a problem here with the deleteReason that does not exist in the backend structure of the deleteSession because sessions are deleted
//so there is no session in wich we can put the deleteReason
export const deleteSession = async ({
  sessionId,
  reason,
}: {
  sessionId: string;
  reason: {
    cancelingReason: string;
    cancelingDescription: string;
  };
}) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/session?sessionId=${sessionId}&cancelingReason=${reason.cancelingReason}&cancelingDescription=${reason.cancelingDescription}`,
  });
};
// Sessions: Batch Delete
export const batchDeleteSessions = async (sessionIds: any) => {
  const stringifySessionIds = JSON.stringify(sessionIds);
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/session/batchDelete?sessionIds=${stringifySessionIds}`,
  });
};

/*
    [SETUP] - Scholar Year
*/

// Fetch All Scholar Year
export const fetchAllScholarYear = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/scholarYear/all`,
  });
};

// Get Scholar Year By ID
export const getScholarYear = async ({ queryKey: [_, scholarYearId] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/scholarYear?scholarYearId=${scholarYearId}`,
  });
};

// Add Scholar Year
export const addScholarYear = async ({ scholarYearData }: any) => {
  const data = new FormData();
  data.append("scholarYear", JSON.stringify(scholarYearData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/scholarYear`,

    data,
  });
};

// Update Scholar Year
export const updateScholarYear = async ({ scholarYearData }: any) => {
  const data = new FormData();
  data.append("scholarYear", JSON.stringify(scholarYearData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/scholarYear`,

    data,
  });
};

// Delete Scholar Year
export const deleteScholarYear = async (scholarYearId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/scholarYear?scholarYearId=${scholarYearId}`,
  });
};

/*
    [SETUP] - School Zones 
*/

// Fetch All School Zones
export const fetchSchoolZones = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/schoolZones`,
  });
};

// Get School Zone By ID
export const getSchoolZone = async ({ queryKey: [_, schoolZoneId] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/schoolZone?schoolZoneId=${schoolZoneId}`,
  });
};

// Add School Zone
export const addSchoolZone = async ({ zoneName }: any) => {
  const data = new FormData();
  data.append("zoneName", zoneName);

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/schoolZone`,

    data,
  });
};

// Update School Zone Holidays
export const updateSchoolZoneHolidays = async (schoolZoneHolidaysData: any) => {
  const data = new FormData();
  data.append(
    "schoolZoneHolidays",
    JSON.stringify(schoolZoneHolidaysData.schoolZoneHolidays)
  );
  data.append("schoolZoneId", schoolZoneHolidaysData.schoolZoneId);

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/schoolZone`,

    data,
  });
};

// Delete School Zone
export const deleteSchoolZone = async (schoolZoneId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/schoolZone?schoolZoneId=${schoolZoneId}`,
  });
};

/*
    [SETUP] - Public Holidays
*/

// Fetch All Public holidays
export const fetchPublicHolidays = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/publicHolidays`,
  });
};

// Get Public Holiday By ID
export const getPublicHoliday = async ({
  queryKey: [_, publicHolidayId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/publicHoliday?publicHolidayId=${publicHolidayId}`,
  });
};

// Add Public Holiday
export const addPublicHoliday = async ({ publicHolidayData }: any) => {
  const data = new FormData();
  data.append("name", publicHolidayData.name);
  data.append("date", publicHolidayData.date);
  data.append("tutoringAllowed", publicHolidayData.tutoringAllowed);
  data.append("occursEveryYear", publicHolidayData.occursEveryYear);

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/publicHoliday`,

    data,
  });
};

// Update Public Holiday
export const updatePublicHoliday = async ({ publicHolidayData }: any) => {
  const data = new FormData();
  data.append("name", publicHolidayData.name);
  data.append("date", publicHolidayData.date);
  data.append("tutoringAllowed", publicHolidayData.tutoringAllowed);
  data.append("occursEveryYear", publicHolidayData.occursEveryYear);
  data.append("publicHolidayId", publicHolidayData._id);

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/publicHoliday`,

    data,
  });
};

// Delete Public Holiday
export const deletePublicHoliday = async (publicHolidayId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/publicHoliday?publicHolidayId=${publicHolidayId}`,
  });
};

/*
    [SETUP] - Availability
*/

// Fetch All Availabilities
export const fetchAvailabilities = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/availabilities`,
  });
};

export const getAvailability = async ({
  queryKey: [_, availabilityId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/availability?availabilityId=${availabilityId}`,
  });
};

// Fetch Availabilities
export const fetchAvailabilitiesByProgram = async (name: string) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/availabilities?type=${name}`,
  });
};

// Update Availability
export const updateAvailabilities = async (availabilitiesData: any) => {
  const data = new FormData();
  data.append("availability", JSON.stringify(availabilitiesData.availability));
  data.append("availabilityId", availabilitiesData.availabilityId);

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/availability`,

    data,
  });
};

// Fetch Government Sectors
export const fetchGouvermentSectors = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/gov/sectors/`,
  });
};

// Fetch All Sectors
export const fetchSectors = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/sectors`,
  });
};

// Fetch autocomplete results for sector name and coordinator name
export const fetchSectorAutoComplete = async ({
  queryKey: [_, filterQuery],
}: any) => {
  const FormData = require("form-data");
  const data = new FormData();

  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/sector/autoComplete?filter=${JSON.stringify(filterQuery)}`,

    data,
  });
};

// Fetch all sectors Filter, sorting, and Pagination
export const fetchSectorDashboard = async ({
  queryKey: [_, filter, sortBy, page],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/sector/dashboard?filter=${JSON.stringify(filter)}&sortBy=${JSON.stringify(
      sortBy
    )}&pageSize=${TABLE_PAGE_SIZE}&page=${JSON.stringify(page)}`,
  });
};

// Fetch all sectors Filter, sorting, and Pagination
export const fetchSectorCSV = async ({
  queryKey: [_, filter, sortBy, page],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/sector/dashboard/csv?filter=${JSON.stringify(
      filter
    )}&sortBy=${JSON.stringify(
      sortBy
    )}&pageSize=${TABLE_PAGE_SIZE}&page=${JSON.stringify(page)}`,
  });
};

// Get Sectors By ID
export const getSector = async ({ queryKey: [_, sectorId] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/sector?sectorId=${sectorId}`,
  });
};

// Add Sector
export const addSector = async ({ sectorData }: any) => {
  const data = new FormData();
  data.append("sector", JSON.stringify(sectorData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/sector`,

    data,
  });
};

// Update Sector
export const updateSector = async ({ sectorData }: any) => {
  const data = new FormData();
  data.append("sector", JSON.stringify(sectorData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/sector`,

    data,
  });
};

// Delete Sector
export const deleteSector = async (sectorId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/sector?sectorId=${sectorId}`,
  });
};

/*
    [Profile and Roles] - Administrators
*/
// Admin Sign in
export const adminSignIn = async ({
  email,
  accessToken,
}: {
  email: string;
  accessToken: string;
}) => {
  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/user/admin/signIn`,
    data: { identifier: email, token: accessToken },
  });
};
export const userSignIn = async ({
  email,
  role,
  accessToken,
}: {
  email: string;
  role: string;
  accessToken?: string;
}) => {
  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/user/signIn?role=${role}`,
    data: { identifier: email, token: accessToken },
  });
};
export const getAdminStats = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministrations/stats`,
  });
};

// Add User Administrator
export const AddUserAdministrator = async ({ userData }: any) => {
  const data = new FormData();
  data.append("userAdministrationData", JSON.stringify(userData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministration`,

    data,
  });
};

// Delete Administrator
export const deleteAdministrator = async (administratorId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministration?userId=${administratorId}`,
  });
};

// TUTORS: Gets all the tutors for the dashboard
export const getTutors = async ({ queryKey: [_, filters] }: any) => {
  const {
    page,
    pageSize,
    filter,
    sortBy,
    exportCsv,
    userRole,
    userId,
    program,
  } = filters;
  if (!page || !pageSize || filter === undefined) return;
  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null)
    queryString += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
  if (sortBy !== null) queryString += `&sortBy=${JSON.stringify(sortBy)}`;
  if (userRole !== null) queryString += `&userRole=${userRole}`;
  if (userId !== null) queryString += `&userId=${userId}`;
  if (program !== null) queryString += `&program=${program}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/is/dashboard${queryString}`,
    // headers: {
    //   userId: cookies.get("userId"),
    // },
  });
};
export const getDashboardTutors = async ({
  queryKey: [_, situation, program, pagination],
}: any) => {
  return await axiosInstance.get(
    `${process.env.REACT_APP_SERVER_URL}/tutor/is/dashboard`,
    {
      headers: {
        userId: cookies.get("userId"),
        // "api-key": process.env.REACT_APP_SERVER_API_KEY,
      },
      params: {
        ...pagination,
        filter: { situation: situation, program: program },
      },
    }
  );
};
// TUTORS: Fetch all Reports for a specific tutor on Spaces
export const fetchTutorReports = async ({ queryKey: [_, filters] }: any) => {
  const { userId, page, pageSize, filter, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?userId=${userId}&page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/reports/dashboard${queryString}`,
  });
};

// TUTORS: Get all tutors for CSV
export const getTutorsCsv = async (filters: any) => {
  const { page, pageSize, filter, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/is/dashboard${queryString}`,
    headers: {
      userId: cookies.get("userId"),
    },
  });
};

// TUTORS: Get the tutor by ID
export const getTutor = async ({ queryKey }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/is?userId=${queryKey[1]}`,
  });
};

// TUTORS: Add a new tutor
export const addTutor = async ({ tutorData }: any) => {
  const data = new FormData();
  data.append("tutorData", JSON.stringify(tutorData));
  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/is`,

    data,
  });
};

// TUTORS: Edit tutor by ID
export const updateTutor = async ({ tutorData }: any) => {
  const data = new FormData();
  data.append("tutorData", JSON.stringify(tutorData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/is`,
    data,
  });
};
export const updateTutorSimpleFields = async ({ tutorData }: any) => {
  return await axiosInstance({
    method: "patch",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/update/simple`,
    data: tutorData,
  });
};
export const updateStudentSimpleFields = async ({ studentData }: any) => {
  return await axiosInstance({
    method: "patch",
    url: `${process.env.REACT_APP_SERVER_URL}/student/update/simple`,
    data: studentData,
  });
};
//TUTORS : Update tutor availability by patch
export const updateTutorAvailability = async (availabilityData: any) => {
  return await axiosInstance({
    method: "patch",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/is/availability`,
    data: availabilityData,
  });
};
// TUTORS: Delete tutor by ID
export const deleteTutor = async (tutorId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/is/?userId=${tutorId}`,
  });
};
//VSCS : Get all VSCs based on Department
export const getVscsBasedOnDepartment = async ({
  queryKey: [_, departmentId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/vscsBasedOnDepartment?departmentId=${departmentId}`,
  });
};
// VSCS: Delete vsc by ID
export const deleteVsc = async (vscId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/vsc?vscId=${vscId}`,
  });
};

// PARENTS: Gets all the parents for the dashboard
export const getParents = async ({ queryKey: [_, filters] }: any) => {
  const { page, pageSize, filter, sortBy, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null)
    queryString += `&filter=${encodeURIComponent(JSON.stringify(filter))}`;
  if (sortBy !== null) queryString += `&sortBy=${JSON.stringify(sortBy)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/is/dashboard${queryString}`,
  });
};

// PARENTS: Get all parents for CSV
export const getParentsCsv = async (filters: any) => {
  const { page, pageSize, filter, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/is/dashboard${queryString}`,
  });
};

// PARENTS: Get the parent by ID
export const getParent = async ({ queryKey }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/is?userId=${queryKey[1]}`,
  });
};
//get parent preferences
export const getParentPreferences = async ({ queryKey }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/preferences?userId=${queryKey[1]}`,
  });
};
// PARENTS: Add a new parent
export const addParent = async ({ parentData }: any) => {
  const data = new FormData();
  data.append("parentData", JSON.stringify(parentData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/is`,

    data,
  });
};
// PARENTS: Update parent password in firebase
export const updateUserFIrebasePAssword = async (
  credentials: { [key: string]: string },
  role: "parent" | "tutor"
) => {
  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/${role}/update-pass`,

    data: credentials,
  });
};

// PARENTS: Edit parent by ID
export const updateParent = async ({ parentData }: any) => {
  const data = new FormData();
  data.append("parentData", JSON.stringify(parentData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/is`,

    data,
  });
};
// PARENTS: Update parent preferences
export const updateParentPreferences = async (
  parentId: string,
  parentData: any
) => {
  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/onBoarding?userId=${parentId}`,
    data: parentData,
  });
};
// PARENTS: Delete parent by ID
export const deleteParent = async (parentId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/is?userId=${parentId}`,
  });
};

// STUDENTS: Gets all the students for the dashboard
export const getStudents = async ({ queryKey: [_, filters] }: any) => {
  const { page, pageSize, sortBy, filter, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;
  if (sortBy !== null) queryString += `&sortBy=${JSON.stringify(sortBy)}`;
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/student/is/dashboard${queryString}`,
  });
};

// STUDENTS: Get all students for CSV
export const getStudentsCsv = async (filters: any) => {
  const { page, pageSize, filter, exportCsv } = filters;
  if (!page || !pageSize || filter === undefined) return;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(filter)}`;

  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/student/is/dashboard${queryString}`,
  });
};

// STUDENTS: Get the student by ID
export const getStudent = async ({ queryKey }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/student/is?userId=${queryKey[1]}`,
  });
};

// STUDENTS: Add a new parent
export const addStudent = async ({ studentData }: any) => {
  const data = new FormData();
  data.append("studentData", JSON.stringify(studentData));

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/student/is`,

    data,
  });
};

// STUDENTS: Edit parent by ID
export const updateStudent = async ({ studentData }: any) => {
  const data = new FormData();
  data.append("studentData", JSON.stringify(studentData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/student/is`,

    data,
  });
};

// STUDENTS: Delete student by ID
export const deleteStudent = async (studentId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/student/is?userId=${studentId}`,
  });
};

// EXTERNAL USER - Accept Invitation
export const acceptInvitation = async (acceptInvitationData: any) => {
  const data = new FormData();
  data.append("firebaseIdentifier", acceptInvitationData?.firebaseIdentifier);
  data.append("invitationCode", acceptInvitationData?.invitationCode);

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/externalUser/acceptInvitation`,
    headers: {
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
    },
    data,
  });
};

// EXTERNAL USER - Register
export const externalUserRegister = async ({ registerData }: any) => {
  const data = new FormData();
  data.append("firebaseIdentifier", registerData?.firebaseIdentifier);
  data.append("firebaseUserId", registerData?.firebaseUserId);
  data.append("provider", registerData?.provider);
  data.append(
    "profilePic",
    registerData?.profilePic ? registerData?.profilePic : ""
  );
  data.append("userId", registerData?.userId);

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/externalUser/register`,
    headers: {
      "api-key": process.env.REACT_APP_SERVER_API_KEY,
    },
    data,
  });
};

// Send invitaiton User Administrator
export const sendEmailInvitationUserAdministrator = async ({
  email,
  userRole,
}: any) => {
  const data = new FormData();
  data.append("email", email);
  data.append("userRole", userRole);

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministration/sendInvitationEmail`,

    data,
  });
};

// Send invitaiton to Parent
export const sendEmailInvitationToStudent = async ({
  childUserId,
  firebaseIdentifier,
  userRole,
}: any) => {
  const data = new FormData();
  data.append("childUserId", childUserId);
  data.append("firebaseIdentifier", firebaseIdentifier);
  data.append("userRole", userRole === "parent" ? userRole : "student");
  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/parent/send-invitation`,

    data,
  });
};
export const sendEmailInvitationToTutor = async ({ email, userRole }: any) => {
  const data = new FormData();
  data.append("email", email);
  data.append("userRole", userRole);

  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/tutor/send-invitation`,

    data,
  });
};

// Update User Administrator
export const updateUserAdministrator = async ({ userData }: any) => {
  const data = new FormData();
  data.append("userAdministrationData", JSON.stringify(userData));

  return await axiosInstance({
    method: "put",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministration`,

    data,
  });
};

export const updateUserPasswordStatus = async ({
  userId,
  hasChangedPassword,
  userRole,
  email,
}: any) => {
  try {
    const data = { userId, hasChangedPassword, userRole, email };

    return await axiosInstance({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}/user/password-status`,
      data,
    });
  } catch (error) {
    console.error("Error while updating password status:", error);
    throw error;
  }
};

// Get List of administrator users (base on Query parameters)
export const getUserAdministrators = async ({
  queryKey: [_, queryParam],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministrations?${queryParam}`,
  });
};

// Get user administration details based on adminID
export const getUserAdministrator = async ({ queryKey: [_, userId] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministration?userId=${userId}`,
  });
};
export const getUserAdministratorRole = async (userId: string) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministrationRole?userId=${userId}`,
  });
};
export const getAllCoordinators = async ({
  queryKey: [_, program, departmentIds, establishmentIds],
}: any) => {
  const url =
    program === "az-fac-5f87232"
      ? `${process.env.REACT_APP_SERVER_URL}/coordinators?program=${program}&establishmentId=${JSON.stringify(establishmentIds)}`
      : `${process.env.REACT_APP_SERVER_URL}/coordinators?program=${program}&depIds=${JSON.stringify(departmentIds)}`;

  return await axiosInstance({
    method: "get",
    url,
  });
};

export const getCoordinatorsOfSector = async ({
  queryKey: [_, sectorId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/coordinator/sector?sectorId=${sectorId}`,
  });
};
//Get Coordinators based on sectorIds
export const getCoordinatorBasedOnDepartment = async ({
  queryKey: [_, departmentCode, program],
}: any) => {
  const departmentCodes = departmentCode.map((code: any) => code.departmentId);

  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/userAdministration/coordinatorsBaseOnDepartment?departmentCode=${JSON.stringify(
      departmentCodes
    )}&program=${program}`,
  });
};

export const deleteCoordinator = async (coordinatorId: any) => {
  return await axiosInstance({
    method: "delete",
    url: `${process.env.REACT_APP_SERVER_URL}/coordinator?coordinatorId=${coordinatorId}`,
  });
};

// FetchUserAutocomplete
export const fetchUsersAutoComplete = async ({
  queryKey: [_, search, userRole, program],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/userAdministrations/search?search=${search}&userRole=${userRole}&program=${program}`,
  });
};

// LEVELS: get all levels
export const fetchAllLevels = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/levels`,
  });
};

// LEVELS: get single level
export const fetchSingleLevel = async ({ queryKey: [_, levelId] }: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/level?levelId=${levelId}`,
  });
};

// Fetch specilalities base on level group
export const fetchSpialtiesByGroupLevel = async ({
  queryKey: [_, group],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/specialties-level?group=${group}`,
  });
};

// AUTOCOMPLETE: Fetch Classes based on Establishments
export const fetchClassesAutoComplete = async ({
  queryKey: [_, levelIds],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/class/baseOnLevel?levelIds=[${JSON.stringify(levelIds)}]`,
  });
};

// Classes Fetch Based on Id
export const fetchClassesBasedOnLeve = async ({
  queryKey: [_, levelId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${
      process.env.REACT_APP_SERVER_URL
    }/class/baseOnLevel?levelIds=[${JSON.stringify(levelId)}]`,
  });
};

// MATCHING: Gets all the matches
export const getMatchings = async ({ queryKey: [_, filters] }: any) => {
  const { page, pageSize, filter, exportCsv, sortBy } = filters;
  if (!page || !pageSize || filter === undefined) return;

  const cloneFilter = structuredClone(filter);

  let url = process.env.REACT_APP_SERVER_URL;
  url +=
    cloneFilter.mode === "tutor"
      ? `/matching/tutor/dashboard`
      : `/matching/student/dashboard`;

  delete cloneFilter.mode;

  let queryString = `?page=${page}&pageSize=${pageSize}&exportCsv=${exportCsv}&sortBy=${JSON.stringify(sortBy)}`;
  if (filter !== null) queryString += `&filter=${JSON.stringify(cloneFilter)}`;

  url += queryString;
  return await axiosInstance({
    method: "get",
    url,
  });
};

// MATCHING: Gets paired students
export const MatchingPaired = async ({
  queryKey: [_, userId, filters],
}: any) => {
  const { tutorAvailability, filter } = filters;

  let queryString = `?userId=${userId}`;
  // queryString += filter.mode === "tutor" ? `&studentAvailability=${JSON.stringify(tutorAvailability)}` : `&tutorAvailability=${JSON.stringify(tutorAvailability)}`
  if (tutorAvailability.fullTime !== "")
    queryString += `&tutorAvailability=${JSON.stringify(tutorAvailability)}`;

  let url = process.env.REACT_APP_SERVER_URL;

  url +=
    filter.mode === "tutor"
      ? `/matching/tutor/students-matched`
      : `/matching/student/tutor-matched`;
  url += queryString;

  return await axiosInstance({
    method: "get",
    url,
  });
};

// MATCHING: Gets paired check
export const MatchingPairedCheck = async ({ userId, filter }: any) => {
  let queryString = `?userId=${userId}`;
  // queryString += filter.mode === "tutor" ? `&studentAvailability=${JSON.stringify(tutorAvailability)}` : `&tutorAvailability=${JSON.stringify(tutorAvailability)}`

  let url = process.env.REACT_APP_SERVER_URL;

  url +=
    filter.mode === "tutor"
      ? `/matching/tutor/students-matched`
      : `/matching/student/tutor-matched`;
  url += queryString;

  return await axiosInstance({
    method: "get",
    url,
  });
};

// MATCHING: Not Matched Matches
export const MatchingNotPaired = async ({
  queryKey: [_, userId, filters],
}: any) => {
  const {
    sectorId,
    departmentId,
    levelId,
    gender,
    tutorAvailability,
    filter,
    subjects,
    search,
    program,
    is_suzali,
  } = filters;
  let queryString = `?userId=${userId}&sectorId=${JSON.stringify(
    sectorId
  )}&departmentId=${departmentId}&levelId=${JSON.stringify(
    levelId
  )}&gender=${gender}&subject=${subjects}&program=${program}`;
  if (search !== "")
    queryString +=
      filter.mode === "tutor"
        ? `&studentName=${search}`
        : `&tutorName=${search}`;

  tutorAvailability &&
    (queryString +=
      filter.mode === "tutor"
        ? `&tutorAvailability=${JSON.stringify(tutorAvailability)}`
        : `&studentAvailability=${JSON.stringify(tutorAvailability)}`);
  if (is_suzali) {
    queryString += `&is_suzali=${is_suzali}`;
  }
  let url = process.env.REACT_APP_SERVER_URL;
  url +=
    filter.mode === "tutor"
      ? `/matching/tutor/students-unmatched`
      : `/matching/student/tutors-unmatched`;
  url += queryString;

  return await axiosInstance({
    method: "get",
    url,
  });
};

export const SessionId = async ({
  queryKey: [_, studentId, sessionId],
}: any) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/session/join-url?userId=${studentId}&sessionId=${sessionId}`,
  });
};
export const getDepartment = async (departmentCode: any) => {
  return await axiosInstance({
    method: "get",
    url: `https://geo.api.gouv.fr/communes?codePostal=${departmentCode}`,
  });
};
export const studentReportAppreciation = async ({
  reportId,
  userId,
  rating,
  satisfaction,
  appreciation,
}: {
  reportId: string;
  userId: string;
  rating: number;
  satisfaction: string;
  appreciation: string;
}) => {
  return await axiosInstance({
    method: "patch",
    // add the userId qury param
    url: `${process.env.REACT_APP_SERVER_URL}/crscreport/student/appreciation?userId=${userId}`,

    data: {
      _id: reportId,
      rating,
      note: satisfaction,
      appreciation,
    },
  });
};
export const generateAccessToken = async (userId: string) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/generateAccessToken?userId=${userId}`,
  });
};
//METCHING: Get STATS
export const getMatchingStats = async () => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/matching/stats`,
  });
};
export async function fetchUserProfile(accessToken: string) {
  const response = await fetch(
    "https://openidconnect.googleapis.com/v1/userinfo",
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  if (response.ok) {
    const userInfo = await response.json();
    return userInfo;
  } else {
    console.error("Failed to fetch user profile", response.statusText);
    return null;
  }
}
export const checkEmailValidation = async (userId: string) => {
  return await axiosInstance({
    method: "get",
    url: `${process.env.REACT_APP_SERVER_URL}/user/checkEmailValidation?userId=${userId}`,
  });
};

export const generateNewGoogleMeetId = async () => {
  try {
    const {
      data: { data },
      status,
    } = await axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_SERVER_URL}/session/generate-google-meet-url`,
    });
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const matchStudentToTutor = async (matchData: any) => {
  try {
    const {
      data: { data },
      status,
    } = await axiosInstance({
      method: "put",
      url: `${process.env.REACT_APP_SERVER_URL}/tutor/matchedStudents`,
      data: matchData,
    });
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.log("error", error);
  }
};
export const checkTutorStudentAvailability = async ({ queryKey }: any) => {
  const userId = queryKey[1];
  const tutor = queryKey[2];
  const availability = queryKey[3];
  try {
    const {
      data: { data },
      status,
    } = await axiosInstance({
      method: "get",
      url: `${process.env.REACT_APP_SERVER_URL}/matching/checkAvailability?userId=${userId}&tutor=${tutor}&availability=${JSON.stringify(
        availability
      )}`,
    });
    if (status === 200) {
      return JSON.parse(data);
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const checkAffectability = async ({ userData }: any) => {

  
  return await axiosInstance({
    method: "post",
    url: `${process.env.REACT_APP_SERVER_URL}/matching/checkAvailability`,
    data: userData
  });
};