import Line from "../errors/line";
import MenuLogo from "../../layout/components/menu/logo";

import { Helmet } from "react-helmet";
import { Col, Row } from "antd";

export default function Privacy() {
  return (
    <Row className="hp-text-center hp-overflow-hidden">
      <Line />

      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>

      <Col
        span={24}
        className="hp-d-flex-full-center hp-border-bottom-1 hp-border-color-dark-60 hp-py-32"
      >
        <MenuLogo />
      </Col>

      <Col className="hp-error-content hp-py-32" span={24}>
        <Row className="hp-h-100 text-align-start p-25p hp-mt-40">
          <Col>
            <h2 className="h1 hp-mb-16">Privacy Policy</h2>

            <p className="hp-mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
              J’accepte ces conditions de traitement de mes données
              personnelles* : Les données à caractère personnel recueillies dans
              le cadre de ce formulaire d’inscription: données d’identification
              (nom, prénom,pseudo…), données de contact (adresse postale, email,
              numéro de téléphone…) et données de connexion (identifiants,
              création de profil...) sont obligatoires pour la gestion de votre
              inscription et de votre participation au mentorat ZUPdeCO.
              <br />
              <br />
              L’association pourra également recueillir et étudier les données
              de connexion et d’utilisation à la plateforme MyMaxicours, dans le
              cadre strict de son activité et notamment de ses besoins en
              évaluation interne.
              <br />
              <br />
              L’association ZUPdeCO conservera les données à caractère personnel
              pour une durée en adéquation avec l’exercice du mentorat et au
              suivi de l’activité de l’association, et ce pour une durée
              maximale de 3 ans après la fin de la relation contractuelle.
              <br />
              <br />
              Conformément à la réglementation applicable, notamment le
              Règlement européen 2016/679, dit règlement général sur la
              protection des données (RGPD) et les dispositions nationales
              relatives à l’informatique, aux fichiers et libertés, les
              personnes dont les données à caractère personnel sont collectées
              bénéficient d’un droit de portabilité, d’un droit d’accès, de
              rectification, de suppression et d’opposition, pour motifs
              légitimes, aux données personnelles les concernant mais également
              du droit de retirer leur consentement à la collecte et au
              traitement de leurs données à tout moment, sans porter atteinte à
              la licéité du traitement fondé sur le consentement effectué avant
              le retrait de celui-ci.
              <br />
              <br />
              <p className="hp-mb-32 hp-p1-body hp-text-color-black-100 hp-text-color-dark-0">
                Dans le cadre de l’évaluation du Plan 1 jeune 1 mentor et pour
                les personnes qui auront acceptés d’être recontactées, les
                données à caractère personnel, seront transmises à l'INJEP (ou
                aux organismes qu’elle aura habilités) à des fins d'analyses
                statistiques et d'études.
                <br />
                <br />
                L’INJEP s’engage à ce que les traitements des données
                personnelles qu’elle met en œuvre soient conformes au{" "}
                <a
                  href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX%3A32016R0679"
                  target="_blank"
                >
                  Règlement général sur la protection des données (RGPD)
                </a>{" "}
                et à la{" "}
                <a
                  href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000000886460"
                  target="_blank"
                >
                  loi Informatique et Libertés.{" "}
                </a>
              </p>
              Ces droits peuvent être exercés par l’envoi d’un courrier, à
              ZUPdeCO, DCCP, au 8 rue du Faubourg Poissonnière 75010 Paris –
              France.
              <br />
              <br />
              Si vous estimez, après nous avoir contactés, que vos droits «
              Informatique et Libertés » ne sont pas respectés, vous pouvez
              adresser une réclamation à la CNIL.
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
