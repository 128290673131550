import { Tooltip } from "antd";
import { RiEyeLine } from "react-icons/ri";
import { PiIntersectBold } from "react-icons/pi";

import { Link, useLocation } from "react-router-dom";
import { Translation } from "../../utils";
import { AddReport, DeleteIcon, EditIcon } from "../icons";
import { useAccessControl } from "../../hooks/useAccessControl";
import { PermissionActions } from "../../utils/const";
import { useLocalStorage } from "../../hooks";

import { useState, useEffect } from "react";
import ModalAttendance from "./ModalUsersAttendance";
import moment from "moment";
import MatchingIcon from "../icons/MatchingIcons";
type ListTabActionsProps = {
  item?: string;
  page: string;
  path: string;
  method: any;
  id: string;
  sessionData?: any;
  sessionId?: string;
  setReportName?: any;
  openModal?: any;
  target: string | null;
  allowAdd?: boolean;
  allowView?: boolean;
  allowEdit?: boolean;
  allowDelete?: boolean;
  allowViewReport?: boolean;
  allowPairing?: boolean;
};

export default function ListTabActions(props: ListTabActionsProps) {
  // Privilages
  const [user] = useLocalStorage("user", {});
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasAddAccess = useAccessControl(props?.page, PermissionActions.CREATE);
  const hasReadAccess = useAccessControl(props?.page, PermissionActions.READ);
  const hasUpdateAccess = useAccessControl(
    props?.page,
    PermissionActions.UPDATE
  );

  const hasDeleteAccess = useAccessControl(
    props?.page,
    PermissionActions.DELETE
  );

  const {
    item,
    path,
    id,
    method,
    target,
    allowAdd = hasAddAccess,
    allowView = hasReadAccess,
    allowEdit = hasUpdateAccess,
    allowDelete = hasDeleteAccess,
    allowViewReport = hasReadAccess,
    allowPairing,
  } = props;
  const deleteTooltip = Translation("delete");
  const viewTooltip = Translation("view");
  const editTooltip = Translation("edit");
  const addTooltip = Translation("add");
  // const viewReportTooltip = Translation("presence");
  const completedSession =
    moment(props.sessionData?.sessionDate?.startDate) < moment();
  const onOpen = () => {
    props.setReportName(props.sessionId);
    props.openModal();
  };

  const admins = [
    "vsc",
    "coordinator",
    "manager",
    "admin",
    "super_administrator",
  ];

  const redirectionFromId = () => {
    localStorage.setItem(`${item}RedirectId`, id);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  console.log("USER", user);
  return (
    <>
      {allowAdd &&
        location.pathname === "/tutoring/cr-reports" &&
        admins.includes(user.userRole) && (
          <Tooltip placement="top" title={addTooltip}>
            <span onClick={onOpen} className="hp-fs-16 hp-mr-15 pointer">
              <AddReport width="18" height="18" />
            </span>
          </Tooltip>
        )}
      {allowView && (
        <Tooltip placement="top" title={viewTooltip}>
          <Link
            to={`${path}/view`}
            className="hp-fs-16"
            onClick={() => redirectionFromId()}
          >
            <RiEyeLine
              size={19}
              style={{ marginBottom: -1 }}
              className="remix-icon"
            />
          </Link>
        </Tooltip>
      )}

      {allowEdit && (
        <Tooltip placement="top" title={editTooltip}>
          <Link
            to={`${path}/edit`}
            className="hp-fs-16 hp-ml-15"
            onClick={() => redirectionFromId()}
          >
            <EditIcon style={{ marginBottom: -1 }} />
          </Link>
        </Tooltip>
      )}
      {allowDelete && (
        <Tooltip placement="top" title={deleteTooltip}>
          <span
            className="hp-fs-16 hp-ml-15 hp-cursor-pointer"
            onClick={() => method(id, target)}
          >
            <DeleteIcon style={{ marginBottom: -3 }} />
          </span>
        </Tooltip>
      )}
      {allowPairing && user?.contactDetails?.email === "zsaidani@naxxum.fr" && (
        <Tooltip placement="top" title={deleteTooltip}>
          <span
            className="hp-fs-16 hp-ml-15 hp-cursor-pointer"
            onClick={() => method(id, target, "pairing")}
          >
            <PiIntersectBold
              style={{ marginBottom: -3 }}
              color="#ff6b43"
              size={20}
            />
          </span>
        </Tooltip>
      )}
      {/* {allowViewReport && completedSession && (
        <>
          <Tooltip placement="top" title={viewReportTooltip}>
            <span
              className="hp-fs-16 hp-ml-15 hp-cursor-pointer"
              onClick={showModal}
            >
              <TeamIcon />
            </span>
          </Tooltip>
          {isModalOpen && (
            <ModalAttendance
              sessionId={id}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          )}
        </>
      )} */}
    </>
  );
}
